<template>
  <router-link :to="{name: 'bookentry.view', params: {id}}">
    <slot name="default"/>
  </router-link>
</template>

<script>
  export default {
    props: {
      id: {
        type: Number,
        required: true
      }
    }
  }
</script>
