<messages>["./BookEntry"]</messages>

<template>
  <base-layout mw0>
    <v-col cols="12">
      <v-alert
        v-if="createdBookEntryId"
        v-model="isSuccessAlertVisible"
        type="success"
        dismissible>
        <i18n path="create.success">
          <template #link>
            <!-- empty comments in default slot are used to trim spaces from content -->
            <book-entry-link
              :id="createdBookEntryId"
              class="success-alert-link">
              <!---->{{ $t ('create.object') }}<!---->
            </book-entry-link>
          </template>
        </i18n>
      </v-alert>

      <v-card class="mb-6">
        <form
          novalidate
          @submit.prevent="onSubmit()">
          <v-card-title primary-title>
            <div>
              <div
                v-t="`bookentry.create.title`"
                class="text-h5"/>
              <p
                v-t="`create.intro`"
                class="cgwng-subheading"/>
            </div>
          </v-card-title>

          <v-card-text>
            <book-entry-input
              ref="input"
              :v="$v.bookEntry"/>
          </v-card-text>

          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.reset'"
              text
              :disabled="loading"
              @click="reset"/>

            <v-btn
              v-t="`general.button.create`"
              color="primary"
              :loading="loading"
              type="submit"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import BookEntryInput, {
    data as newBookEntry,
    validation as bookEntryValidation
  } from './components/BookEntryInput'
  import BookEntryLink from '@/app/pages/Account/components/BookEntryLink'

  export default {
    name: 'CreateBookEntry',

    components: {
      BookEntryLink,
      BaseLayout,
      BookEntryInput
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        createdBookEntryId: null,
        loading: false,
        bookEntry: newBookEntry ()
      }
    },

    validations: {
      bookEntry: bookEntryValidation
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      onSubmit () {
        this.isSuccessAlertVisible = false

        if (!this.$v.$invalid) {
          this.loading = true

          this.fetchData ({
            op: 'account/deposit',
            params: {
              data: this.bookEntry
            },
            cb: data => {
              this.isSuccessAlertVisible = true
              this.createdBookEntryId = data.id

              this.reset ()
            },
            cbFinal: () => {
              this.loading = false
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      reset () {
        this.bookEntry = newBookEntry ()
        this.$v.$reset ()
        this.$refs.input.reset ()
      }
    }
  }
</script>
