<messages>["../BookEntry"]</messages>

<template>
  <v-row>
    <v-col cols="12" sm="3">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        class="load-progress"
        color="primary"/>
      <v-text-field
        v-else
        disabled
        :label="$t ('label.afterConversion')"
        :error-messages="error ?[$t ('label.conversionError')] : []"
        class="dstAmount text-right"
        :value="dstAmount"/>
    </v-col>
    <v-col cols="12" sm="6">
      <currency-select
        ref="dstCurrency"
        v-model="dstCurrency"
        :exclude="[srcCurrency]"
        :label="$t ('label.destinationCurrency')"/>
    </v-col>
    <v-col cols="12" sm="3">
      <v-text-field
        disabled
        :label="$t ('label.exchangeRate')"
        class="dstAmount text-right"
        :value="exchangeRate"/>
    </v-col>
  </v-row>
</template>

<script>
  import _debounce from 'lodash/debounce'

  import {mapActions} from 'vuex'

  import CurrencySelect from '@/app/core/components/CurrencySelect'

  export default {
    components: {
      CurrencySelect
    },

    props: {
      value: {
        type: String,
        default: ''
      },

      srcAmount: {
        type: [String, Number],
        default: undefined
      },

      srcCurrency: {
        type: String,
        default: ''
      }
    },

    data () {
      return {
        isLoading: false,
        conversionInfo: null,
        error: false
      }
    },

    computed: {
      conversionInfoData () {
        return {
          sourceCurrency: this.srcCurrency,
          destinationCurrency: this.dstCurrency,
          amount: this.srcAmount
        }
      },

      isConversionInfoDataValid () {
        return this.srcCurrency && this.dstCurrency && this.srcAmount
      },

      dstAmount () {
        return this.conversionInfo?.amount
      },

      dstCurrency: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      exchangeRate () {
        return this.conversionInfo?.exchangeRate
      }
    },

    watch: {
      conversionInfoData: {
        handler () {
          if (this.isConversionInfoDataValid) {
            this.getConversionInfo ()
          }
        },
        immediate: true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      reset () {
        this.isLoading = false
        this.destAmount = null
        this.dstCurrency = ''
        this.conversionInfo = null
      },

      getConversionInfo: _debounce (function () {
        this.isLoading = true
        this.error = false

        const errorHandler = (data, next) => {
          this.error = true
          this.conversionInfo = null
        }

        this.fetchData ({
          op: 'client/data/currency-conversion-info',
          params: {data: this.conversionInfoData},
          cb: data => {
            this.conversionInfo = data.data
          },
          cbError: errorHandler,
          cbCatch: errorHandler,
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }, 500)
    }
  }
</script>
