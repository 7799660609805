<messages>["../BookEntry"]</messages>

<template>
  <fieldset>
    <v-row>
      <v-col cols="12">
        <client-select
          required
          for-accounting
          :v="v.clientId"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="bookEntry.amount"
          class="required"
          type="number"
          step="0.01"
          :label="$t ('label.amount')"
          :error-messages="validationErrors(
            'amount', validationMessages.amount)"
          @blur="v.amount.$touch"/>
      </v-col>
      <v-col cols="12" sm="6">
        <currency-select
          required
          :v="v.currency"/>
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="exchange"
          :label="$t (`label.exchange`)"
          hide-details/>
      </v-col>
      <template v-if="exchange">
        <v-col cols="12">
          <exchange-input
            ref="exchangeInput"
            v-model="bookEntry.destinationCurrency"
            :src-currency="bookEntry.currency"
            :src-amount="bookEntry.amount"/>
        </v-col>
      </template>
      <v-col cols="12">
        <v-text-field
          v-model.trim="bookEntry.memorandumText"
          :label="$t ('label.memorandumText')"/>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model.trim="bookEntry.transaction"
          :label="$t ('label.transaction')"/>
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="needEnterDate"
          :label="$t (`label.needEnterDate`)"
          hide-details/>
      </v-col>
      <v-col cols="12">
        <date-picker-field
          v-if="needEnterDate"
          v-model="bookEntry.date"
          :allowed-dates="past"
          :label="$t ('label.date')"/>
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
  import ClientSelect, {
    validation as clientIdValidation,
    validationMessages as clientIdValidationMessages
  } from '@/app/core/components/ClientSelect'

  import CurrencySelect from '@/app/core/components/CurrencySelect'
  import DatePickerField, {past} from '@/app/core/components/DatePickerField'
  import ExchangeInput from './ExchangeInput'

  import {required, decimal} from 'vuelidate/lib/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  /**
   * function to create new book entry object
   */
  export const data = () => ({
    clientId: null,
    amount: 0,
    currency: '',
    destinationCurrency: '',
    date: null,
    transaction: '',
    memorandumText: ''
  })

  /**
   * validation object for book entry input
   */
  export const validation = {
    clientId: clientIdValidation,
    amount: {
      required,
      decimal,
      nonzero: v => Number.parseFloat (v) !== 0
    },
    currency: {required}
  }

  /**
   * validation type message attributes for the validation object
   */
  export const validationMessages = {
    clientId: clientIdValidationMessages,
    amount: {
      required: 'validation.amount.required',
      decimal: 'validation.amount.decimal',
      nonzero: 'validation.amount.nonzero'
    },
    currency: {
      required: 'validation.currency.required'
    }
  }

  export default {
    name: 'BookEntryInput',

    components: {
      ClientSelect,
      CurrencySelect,
      ExchangeInput,
      DatePickerField
    },

    mixins: [validationMixins],

    props: {
      // the validations sub-tree to associate with this component
      v: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        needEnterDate: false,
        savedDestinationCurrency: '',
        exchange: false,
        validationMessages,
        past: past ()
      }
    },

    computed: {
      bookEntry () {
        return this.v.$model
      }
    },

    watch: {
      exchange (newVal) {
        if (!newVal) {
          this.savedDestinationCurrency = this.v.$model.destinationCurrency
          this.v.$model.destinationCurrency = ''
        } else {
          if (this.savedDestinationCurrency && this.savedDestinationCurrency !== '') {
            this.v.$model.destinationCurrency = this.savedDestinationCurrency
          }
        }
      }
    },

    methods: {
      allowedDates: dateStr => {
        const number = Date.parse (dateStr)
        return number < (new Date ()).getTime ()
      },

      reset () {
        if (this.$refs.exchangeInput) { this.$refs.exchangeInput.reset () }
      }
    }
  }
</script>

<style scoped>
fieldset {
  border: none;
}
</style>
